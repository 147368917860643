import React, { useState } from "react";
import Layout from "../components/layout";
import Twitter from "../components/svg/twitter";
import Fb from "../components/svg/fb";
import Linkedin from "../components/svg/linkedin";
import Insta from "../components/svg/insta";
import Seo from "../components/seo";

import axios from "axios";

import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const SUBMIT_URL =
  "https://53qtxydcmh.execute-api.ap-southeast-2.amazonaws.com/prod/submit";

const TestContactPage = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState();

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    const token = await executeRecaptcha("contactForm");
    const form = e.target;
    const data = new FormData(form);
    data.append("g-recaptcha-response", token);
    axios({
      method: "post",
      url: SUBMIT_URL,
      headers: { "Content-Type": "multipart/form-data" },
      data,
    })
      .then((r) => {
        setProcessing(false);
        if (typeof window !== "undefined") {
          window.location = "/thanks/";
        }
      })
      .catch((r) => {
        setError("An error occurred, please try again later");
        setProcessing(false);
      });
  };

  return (
    <Layout>
      <Seo
        title=""
        description=""
      />

      <section role="main" className="bg-purple white">
        <div className="container">
          <div className="row flex flex-jc">
            <div className="hero-content sub-hero margin-sm">
              <h1
                className="hero-lead"
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-delay="100"
                data-sal-duration="400"
              >
                Contact us
              </h1>
            </div>
          </div>
        </div>
      </section>

      <section className="section-pad-sm" id="anchor">
        <div className="container">
          <div className="row">
            <div className="cols">
              <div className="col-26">
                <h2>How can we help?</h2>
                <p>
                  To discuss a startup, web design or web development project,
                  or request a quote, please complete the form and we'll get
                  right back to you.
                </p>

                <div className="footer-social">
                  <a href="https://twitter.com/Go_LaunchLab">
                    <Twitter />
                  </a>
                  <a href="https://www.facebook.com/launchlabau/">
                    <Fb />
                  </a>
                  <a href="https://www.instagram.com/launchlab_au/">
                    <Linkedin />
                  </a>
                  <a href="https://www.instagram.com/launchlab_au/">
                    <Insta />
                  </a>
                </div>
              </div>

              <div className="col-67 long-text">
                <form
                  className="contact-form"
                  name="contact"
                  id="contact"
                  onSubmit={handleOnSubmit}
                >
                  <div className="form-item">
                    <input
                      type="text"
                      name="name"
                      id="form-name"
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div className="form-item">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div className="form-item">
                    <input
                      type="tel"
                      name="phone"
                      placeholder="Your Phone Number"
                    />
                  </div>
                  <div className="form-item">
                    <textarea
                      type="text"
                      name="message"
                      rows="5"
                      placeholder="How can we help?"
                    ></textarea>
                    <input
                      type="text"
                      name="_gotcha"
                      style={{ display: "none" }}
                    />
                    <input type="hidden" name="_test" value="true" />
                  </div>
                  <div className="form-item">
                    <button
                      type="submit"
                      value="Send"
                      className={
                        "btn btn-lg " + (processing ? "btn-line" : "btn-purple")
                      }
                      disabled={processing}
                    >
                      {processing ? "Please wait...." : "Submit"}
                    </button>
                    {error && <p>{error}</p>}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row">
          <div className="section-line"></div>
        </div>
      </div>
    </Layout>
  );
};

const ContactPageWithRecaptcha = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Leqvb0eAAAAAIFLtwRaZKyFslkxFIVHxiJ6iEKA">
      <TestContactPage />
    </GoogleReCaptchaProvider>
  );
};

export default ContactPageWithRecaptcha;
